.map-hover-tooltip {
    position: absolute;
    margin: 8px;
    padding: 4px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width: 300px;
    font-size: 10px;
    z-index: 9;
    pointer-events: none;
}
.map-top-info-panel {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 0.35rem;
}

.right-panel {
    max-height: 60vh;
}
.right-addresses-panel {
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    font-size: 11px;
}
.geomap-wrapper {
    transition: width 2s ease-in-out;
}
