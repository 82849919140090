// elector

$min-contrast-ratio:		2.5 ;
$enable-negative-margins:	true ;
$link-decoration:			none ;
$font-size-root:			14px ;

// $blue:    #206bc4 ;
// $indigo:  #6574cd ;
// $purple:  #a55eea ;
// $pink:    #f66d9b ;
// $red:     #cd201f ;
// $orange:  #ff922b ;
// $yellow:  #fab005 ;
// $green:   #5eba00 ;
// $teal:    #2bcbba ;
// $cyan:    #17a2b8 ;

$gray-100:	#f7fafc;
$gray-200:	#edf2f7;
$gray-300:	#e2e8f0;
$gray-400:	#cbd5e0;
$gray-500:	#a0aec0;
$gray-600:	#718096;
$gray-700:	#4a5568;
$gray-800:	#2d3748;
$gray-900:	#1a202c;

$blue:		#206bc4;
$indigo:  	#667eea;
$purple:	#7a5195;
$pink:		#ef5675;
$red:		#f56565;
$orange:	#ed8936;
$yellow:	#ffa600;
$green:		#48bb78;
$teal:		#38b2ac;
$cyan:		#17a2b8;

@import "../node_modules/bootstrap/scss/functions";

$azure:   #45aaf2 ;
$lime: 	  #94d82d ;

@import "../node_modules/bootstrap/scss/variables";

$custom-colors: (
        "blue":    $blue,
        "indigo":  $indigo,
        "purple":  $purple,
        "pink":    $pink,
        "red":     $red,
        "orange":  $orange,
        "yellow":  $yellow,
        "green":   $green,
        "teal":    $teal,
        "cyan":    $cyan,

        "azure":  $azure,
        "lime":   $lime,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../node_modules/bootstrap/scss/bootstrap";

// #003f5c
// #2f4b7c
// #665191
// #a05195
// #d45087
// #f95d6a
// #ff7c43
// #ffa600
.btn-success, .btn-green, .btn-outline-green:hover, .btn-success:hover, .btn-outline-success:hover, .btn-check:checked + .btn-outline-green {
  color: #FFFFFF !important;
}
.react-toast-notifications__container {
  z-index: 9999 !important;
}

.emergency-help-button:hover {
  opacity: 0.2;
  cursor: pointer;
  transition: opacity 0.3s;
}
.sr-only {
  display: none;
}
.elector-menu-profile-dropdown .dropdown-menu {
  right: 0 !important;
  left: auto;
}

.form-label-required:after {
  content: ' *';
  color: red;
}

.modal-backdrop.show {
  opacity: 0.3;
}

.result-color-picker {
  border: 1px solid #cbd5e0;;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0.25rem 0.1rem;
  position: relative;
}
.twitter-color-picker_opened {
  position: absolute !important;
  top: -130px;
  left: -140px;
}
@media (max-width: map-get($container-max-widths, 'sm')) {
  .twitter-color-picker_opened {
    left: -140px;
  }
}
.result-color-picker__preview {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}
.possible-result-button__delete {
  width: 16px;
  height: 16px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
}
.possible-result-button__delete:hover {
  background-color: #aaaeb5;
}
.possible-result-button__selected {
  border: 3px $azure solid;
}
.cursor-pointer {
  cursor: pointer;
}

.app-spinner-fixed {
  left: 50%;
  top: 50%;
  margin-left: -50px;
  position: fixed;
}
.app-spinner-inner {
  position: relative;
}
.app-spinner-inner .app-spinner-fixed {
  position: absolute;
  top: 100px;
  left: 0
}

.table-toolbar-selected-rows {
  background-color: transparentize($green, 0.65);
}
.scenario-edit__selected_user {
  color: $gray-600;
  background-color: white;
  border: none;
  border-radius: 0;
  border-bottom: 0.05rem solid $gray-600;
}
.scenario-edit__selected_user:hover {
  background-color: $gray-400;
}
.react-tel-input .special-label {
  display: none;
}
@media (min-width: map-get($container-max-widths, 'sm')) {
  .modal-desktop-wide .modal-dialog{
    width: 100%;
    max-width: map-get($container-max-widths, 'lg');
  }
}
.search-table__filters-panel {
  overflow: hidden;  /* Hide the element content, while height = 0 */
  height: 0;
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}
.search-table__filters-panel.visible {
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}
.table-row__archived {
  color: $gray-400;
  opacity: 0.3;
}
.table-row__comment-badge {
  .MuiBadge-badge {
    top: -5px;
    right: -7px;
    background-color: $yellow;
  }
  &.dense {
    .MuiBadge-badge {
      top: 10px;
      right: 10px;
      background-color: $yellow;
    }
  }
}
.table-row__archived:hover {
  opacity: 0.7;
}
.MuiAutocomplete-root .MuiInputLabel-outlined.MuiInputLabel-shrink {
  margin-top: 3px;
}

.nav-item:hover .dropdown-menu{
  display:block!important
}

.all-distributions-table__row_failure {
  background-color: transparentize($yellow, 0.85);
}

.react-geocoder {
  position: absolute;
  right: 44px;
  top: 10px;
}
.react-geocoder-results {
  position: absolute;
  background-color: white;
  //right: 50px;
  top: 27px;
  border-radius: 4px;
}
.react-geocoder-item {
  border-bottom: 0.05rem solid $gray-600;
  margin-top: 2px;
}
