
.payment-description-tooltip {
  width: 20rem;
  padding: 1rem;

  border-radius: 0.25rem;
  position: absolute;
  background-color: #edf2f7;
  color: #1a202c;
}

.__react_component_tooltip.show {
  background: transparent;
  opacity: 1 !important;
}

.__react_component_tooltip::before, .__react_component_tooltip::after {
  display: none;
}

.payment-tooltip__sum {
  border-top: #4a5568 0.25px solid;
}
